import { Pipe, PipeTransform } from '@angular/core';
import { Event } from '../services/data.model';

@Pipe({
  name: 'leaders'
})
export class LeadersPipe implements PipeTransform {

  transform(events: Event[], isLeader: boolean): any {
    // catch empty list
    if (!events || !events.length) { return []; }

    // return all events where leadersOnly = false
    // if you're a leader, you'll also see the leader only events
    return events.filter(event => !event.leadersOnly || isLeader);
  }

}
