import { Injectable } from '@angular/core';
import {Memory, MemoryPhoto} from './data.model';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MemoriesService {
  memories: AngularFirestoreCollection<Memory>;
  memoryRequests = [];

  constructor(
    private auth: AuthService,
    private afs: AngularFirestore,
  ) {
    this.memories = this.afs.collection('memories');

    // subscribe to which users are currently requesting access to memories
    this.getUsersByMemoryStatus('requested').subscribe(users => {
      this.memoryRequests = users;
    });
  }

  requestAccess(reason: string) {
    return this.afs.collection('access-requests').doc(this.auth.authUser.uid).set({ reason });
  }

  getUsersByMemoryStatus(memoryStatus: string) {
    return this.afs.collection('users', ref => {
      return ref.where('memoryStatus', '==', memoryStatus);
    })
    .snapshotChanges()
    .pipe(map(actions => {
      return _(actions)
        .map(this.mapWithId.bind(this))
        .filter(user => !user.roles.admin)
        .sortBy('name')
        .value();
    }));
  }

  createMemory(memory: Memory, files: MemoryPhoto[]) {
    return this.memories.add(memory).then(async doc => {
      for (const file of files) {
        await doc.collection('photos').add(file);
      }
    });
  }

  updateMemory(memoryId: string, partial: Partial<Memory>) {
    return this.memories.doc(memoryId).update(partial);
  }

  getMemories() {
    return this.memories
      .snapshotChanges()
      .pipe(map(actions => {
        return _(actions)
          .map(this.mapWithId.bind(this))
          .sortBy('timestamp')
          .reverse()
          .value();
      }));
  }

  getMemoryPhotos(id: string) {
    return this.memories
      .doc(id).collection('photos')
      .snapshotChanges()
      .pipe(map(actions => {
        return _(actions)
          .map(this.mapWithId.bind(this))
          .sortBy('path')
          .value();
      }));
  }

  deleteMemory(memoryId: string) {
    return this.memories.doc(memoryId).delete();
  }

  deleteMemoryPhoto(memoryId: string, photoId: string) {
    return this.memories.doc(memoryId).collection('photos').doc(photoId).delete();
  }

  mapWithId(docAction) {
    const doc = docAction.payload.doc || docAction.payload;

    const data = doc.data();
    const id = doc.id;

    // documents from within the memories-drafts collection will have a
    // property of draft set to true so we can target the correct collection
    const draft = doc.ref.path.includes('memories-drafts');

    return { id, draft, ...data };
  }
}
