import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FilterPipe} from './filter.pipe';
import { LeadersPipe } from './leaders.pipe';

@NgModule({
  declarations: [FilterPipe, LeadersPipe],
  imports: [CommonModule],
  exports: [FilterPipe, LeadersPipe]
})
export class PipesModule {
}
