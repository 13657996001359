import { Injectable } from '@angular/core';
import { HouseGroupLeader, HouseGroupStudent, User } from './data.model';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Observable, combineLatest } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class HouseGroupsService {

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService
  ) { }

  addHouseGroupStudent(student: HouseGroupStudent): Promise<DocumentReference> {
    student.houseGroupLeaderUserId = null;
    student.isDeleted = false;
    return this.afs.collection<HouseGroupStudent>('students').add(student);
  }

  updateHouseGroupStudent(id: string, updates: Partial<HouseGroupStudent>) {
    return this.afs.collection<HouseGroupStudent>('students').doc(id).update(updates);
  }

  getLeaders(): Observable<User[]> {
    const maleLeaders$ = this.getMaleLeaders();
    const femaleLeaders$ = this.getFemaleLeaders();
    const auth$ = this.auth.userChanged$.pipe(startWith(this.auth.user));

    // get both male and female leaders and then filter down 
    // to a list of leaders that you're allowed to manage
    return combineLatest(maleLeaders$, femaleLeaders$, auth$, (maleLeaders, femaleLeaders) => {
      let leaders = [];
      if (this.auth.isMaleHouseGroupAdmin()) {
        leaders = leaders.concat(maleLeaders);
      }
      if (this.auth.isFemaleHouseGroupAdmin()) {
        leaders = leaders.concat(femaleLeaders);
      }

      // unique by id, in case any leaders lead both guys 
      // and girls, don't show duplicates in the list
      return _.uniqBy(leaders, 'uid');
    });
  }

  getLeaderBios(): Observable<HouseGroupLeader[]> {
    return this.afs.collection<HouseGroupLeader>('house-group-leaders').valueChanges();
  }

  getMaleLeaders(): Observable<User[]> {
    return this.afs.collection<User>('users', ref => ref
      .where('roles.maleHouseGroupLeader', '==', true))
      .valueChanges();
  }

  getFemaleLeaders(): Observable<User[]> {
    return this.afs.collection<User>('users', ref => ref
      .where('roles.femaleHouseGroupLeader', '==', true))
      .valueChanges();
  }

  getStudentsForLeader(houseGroupLeaderUserId: string): Observable<HouseGroupStudent[]> {
    return this.afs.collection<HouseGroupStudent>('students', ref => ref
      .where('houseGroupLeaderUserId', '==', houseGroupLeaderUserId)
      .where('isDeleted', '==', false))
      .snapshotChanges()
      .pipe(map(actions => {
        return _(actions)
          .map(this.mapWithId.bind(this))
          .sortBy('name')
          .value();
      }));
  }

  getStudents(): Observable<HouseGroupStudent[]> {
    return this.afs.collection<HouseGroupStudent>('students', ref => ref
      .where('isDeleted', '==', false))
      .snapshotChanges()
      .pipe(map(actions => {
        return _(actions)
          .map(this.mapWithId.bind(this))
          .sortBy('name')
          .value();
      }));
  }

  mapWithId(docAction) {
    const doc = docAction.payload.doc || docAction.payload;

    const data = doc.data();
    const id = doc.id;

    return { id, ...data };
  }
}
