import {Pipe, PipeTransform} from '@angular/core';

/**
 * This pipe is used in the following way:
 *
 * <some-component
 *   [item]="item"
 *   *ngFor="let item of items | filter:filterFields:filterText">
 * </some-component>
 *
 * filter - the name of the pipe
 * filterFields - an array of strings that are the fields we'll try to match
 * the filterText with
 * filterText - a string, usually coming from a searchbar that we'll compare to
 * fields on the objects coming in
 *
 * The values in the transform array are the items in this example
 *
 * The output will be a list of items that match the filterText for any of
 * the filterFields provided. In the case of filterText being undefined or an
 * empty string, all values are returned
 *
 * Check out events.page.ts for one example of how a searchbar and the filter
 * pipe are used together to filter events
 */
@Pipe({name: 'filter'})
export class FilterPipe implements PipeTransform {
  transform(values: any[], filterFields: string[], filterText: string): any {
    return values.filter(value => {
      return !filterText || this.matches(value, filterFields, filterText);
    });
  }

  matches(value: any, filterFields: string[], filterText: string) {
    const text = filterText ? filterText.toLowerCase() : '';
    for (const field of filterFields) {
      if (value[field]) {
        const lowercase = value[field].toLowerCase();
        if (lowercase.includes(text)) {
          return true;
        }
      }
    }
    return false;
  }
}
